import { Component, OnInit } from '@angular/core';
import { sharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import * as tree from 'src/assets/tree.json';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public JSONchapters: any[] = (<any>tree).default;
  public chapters: any[];
  public searchString: string;

  constructor(public sharedService: sharedService, private router: Router) {}

  ngOnInit() {
    this.chapters = this.filterChapters();
    this.scrollToActiveMenuItem();
  }
  async scrollToActiveMenuItem() {
    const checkElement = async selector => {
      while ( document.querySelector('app-menu').querySelector(selector) === null) {
        await new Promise( resolve =>  requestAnimationFrame(resolve) )
      }
      return document.querySelector('app-menu').querySelector(selector); 
    };

    checkElement('sh-menu-item[active]').then((selector) => {
      selector.scrollIntoView()
    });
  }
  

  public filterChapters(): any[] {
    const newChapters: any[] = [];
    this.JSONchapters.forEach((chapter) => {
      const newChapter = {
        label: chapter.label,
        name: chapter.name,
        pages: this.searchString
          ? chapter.pages.filter((page) =>
              page.label.toLowerCase().includes(this.searchString)
            )
          : chapter.pages,
      };
      newChapters.push(newChapter);
    });
    return newChapters;
  }

  // handle user click on menu item
  public handleMenuActive(
    event: any,
    chapter: string,
    page: string,
    subpage?: string
  ): void {
    if (!event.target.chapter) {
      // clicking on page leaf nodes
      event.target.active = true;
      this.sharedService.currentChapter = chapter;
      this.sharedService.currentPage = page;
      this.sharedService.currentSubpage = subpage;
      this.sharedService.currentTab = '';
    } else {
      // clicking on chapters
      event.target.active = false;
      if(!event.path[0].classList.contains('first')) {
        event.target.expanded = !event.target.expanded;
      }
    }
    this.router.navigateByUrl(this.sharedService.getFullRoute());
    document.querySelector('.md-wrapper').scrollTop = 0;
  }
}
