import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public keyImage = 1;

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
    this.keyImage = Math.floor(Math.random() * Math.floor(11));
  }

  logIn() {    
    this.auth.login();
  }
}
