<sh-page flat [theme]="sharedService.appTheme">
  
  <app-login *ngIf="!isAuthenticated"></app-login>
  
  <app-access-bar slot="access" *ngIf="isAuthenticated"></app-access-bar>
  <sh-pane slot="left" behavior="collapse" *ngIf="isAuthenticated">
    <app-menu></app-menu>
  </sh-pane>

  <router-outlet *ngIf="isAuthenticated"></router-outlet>
</sh-page>
