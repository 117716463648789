import { Component, OnInit } from '@angular/core';
import { sharedService } from 'src/app/services/shared.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/services/User';

@Component({
  selector: 'app-access-bar',
  templateUrl: './access-bar.component.html',
  styleUrls: ['./access-bar.component.scss'],
})
export class AccessBarComponent implements OnInit {
  constructor( public sharedService: sharedService,
               public authService: AuthService ) {}

  ngOnInit() {}

  logOut(): void {    
    this.authService.logout();
  }

  get user(): User | undefined {
    return this.authService.getUser();
  }

  get isAuthenticated() : boolean {    
    return this.authService.isLoggedIn();
  }
}
