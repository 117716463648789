import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, SecurityContext } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from "./services/auth.service";

import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

import { MsalModule, MsalService, MSAL_INSTANCE, MsalInterceptorConfiguration, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';

import { AppComponent } from './app.component';
import { ContentComponent } from './components/content/content.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuComponent } from './components/menu/menu.component';
import { AccessBarComponent } from './components/access-bar/access-bar.component';
import { SandboxComponent } from './components/sandbox/sandbox.component';

import { sharedService } from './services/shared.service';
import { LoginComponent } from './components/login/login.component';

//! MSAL Configuration
export function MSALInstanceFactory() : IPublicClientApplication {
  return new PublicClientApplication({
    auth:{
      clientId: "d82aa7ac-722e-4902-a717-6a3a50747d50",
      authority: 'https://login.microsoftonline.com/f82969ba-b995-4d80-8bfa-fd22c1d0557a',
      redirectUri: `${window.origin}/welcome/devx-introduction`,
      postLogoutRedirectUri: `${window.origin}/welcome/devx-introduction`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }    
  });
}
export function MSALInterceptorConfigFactory() : MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  }
}

// Markdown HTML Settings
export function markedOptions(): MarkedOptions {
  const renderer = new MarkedRenderer();
  // headings
  renderer.heading = (text: string, level: number) => {
    if (level === 1) {
      return '<sh-text size="super-header">' + text + '</sh-text>';
    } else {
      return (
        '<sh-text size="header-2" class="h' + level + '">' + text + '</sh-text>'
      );
    }
  };
  // paragraphs
  renderer.paragraph = (text: string) => {
    return '<sh-text size="body-1">' + text + '</sh-text>';
  };
  // links
  renderer.link = (href: string, title: string, text: string) => {
    return (
      '<sh-text size="body-1" class="link" href="' +
      href +
      '">' +
      text +
      '</sh-text>'
    );
  };
  // dividers
  renderer.hr = () => {
    return '<sh-divider spacing="m"></sh-divider>';
  };
  return {
    renderer: renderer,    
    gfm: true,    
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    MenuComponent,
    AccessBarComponent,
    SandboxComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptions,
      },
    }),
  ],
  providers: [ sharedService,
               AuthService,
               { 
                provide: MSAL_INSTANCE,
                useFactory: MSALInstanceFactory  
              },
              {
                provide: HTTP_INTERCEPTORS,
                useClass: MsalInterceptor,
                multi: true
              },
              { 
                provide: MSAL_INTERCEPTOR_CONFIG,
                useFactory: MSALInterceptorConfigFactory  
              },
              MsalService, ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
