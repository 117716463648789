<!-- search -->
<sh-search 
  (value-changed)="
    searchString = $event.target.value; chapters = filterChapters()
  "
></sh-search>
<!-- chapters -->
<ng-container *ngFor="let chapter of chapters; index as i">
  <sh-accordion
    *ngIf="chapter.pages.length > 0"
    [expanded]="
      sharedService.currentChapter == chapter.name || searchString?.length > 0
    "
    [label]="chapter.label"
  >
    <div *ngFor="let page of chapter.pages" style="margin: 0;">
      <!-- pages -->
      <sh-menu-item
        class="chapter-sibling"
        [label]="page.label"
        [chapter]="page.pages"
        (click)="handleMenuActive($event, chapter.name, page.name)"
        [expanded]="page.pages && sharedService.currentPage == page.name"
        [active]="
          sharedService.currentChapter == chapter.name &&
          sharedService.currentPage == page.name &&
          !page.pages
        "
      >
        <!-- page badges -->
        <sh-badge *ngIf="page.new" slot="functions"></sh-badge>
        <!-- sub-pages -->
        <div *ngFor="let subpage of page.pages">
          <sh-menu-item
            [label]="subpage.label"
            (click)="
              handleMenuActive(
                $event,
                chapter.name,
                page.name,
                subpage.name
              );
              $event.stopPropagation()
            "
            [active]="
              sharedService.currentPage == page.name &&
              sharedService.currentSubpage == subpage.name
            "
          >
            <!-- sub-page badges -->
            <sh-badge *ngIf="subpage.new" slot="functions"></sh-badge>
          </sh-menu-item>
          <!-- sub-page badge tooltip -->
        </div>
      </sh-menu-item>
    </div>
  </sh-accordion>
</ng-container>
