<sh-tabs class="page-nav" *ngIf="( sharedService.currentChapter === 'interoperability' && 
                                   sharedService.currentPage === 'technology-fields' && 
                                   sharedService.currentSubpage === 'cloud-setup' )">
    <sh-tab-item label="Overview" (click)="handleTabActive('')" [active]="!sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="Prototype" (click)="handleTabActive('prototype')" [active]="sharedService.currentTab == 'prototype'"></sh-tab-item>
</sh-tabs>

<sh-tabs class="page-nav" *ngIf="( sharedService.currentChapter === 'interoperability' && 
                                   sharedService.currentPage === 'technology-fields' && 
                                   sharedService.currentSubpage === 'on-premise-setup' )">
    <sh-tab-item label="Overview" (click)="handleTabActive('')" [active]="!sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="NATS" (click)="handleTabActive('nats')" [active]="sharedService.currentTab == 'nats'"></sh-tab-item>
    <sh-tab-item label="KubeMQ" (click)="handleTabActive('kubemq')" [active]="sharedService.currentTab == 'kubemq'"></sh-tab-item>
    <sh-tab-item label="Kafka" (click)="handleTabActive('kafka')" [active]="sharedService.currentTab == 'kafka'"></sh-tab-item>
    <sh-tab-item label="RabbitMQ" (click)="handleTabActive('rabbitmq')" [active]="sharedService.currentTab == 'rabbitmq'"></sh-tab-item>
</sh-tabs>

<sh-tabs class="page-nav" *ngIf="( sharedService.currentChapter === 'interoperability' && 
                                   sharedService.currentPage === 'technology-fields' && 
                                   sharedService.currentSubpage === 'hybrid-setup' )">
    <sh-tab-item label="Varian Core Platform" (click)="handleTabActive('')" [active]="!sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="Cloud Hub-APIM" (click)="handleTabActive('cloudhub-apim')" [active]="sharedService.currentTab == 'cloudhub-apim'"></sh-tab-item>
</sh-tabs>

<sh-tabs class="page-nav" *ngIf="( sharedService.currentChapter === 'applicationaccess' && 
                                   sharedService.currentPage === 'technology-fields' && 
                                   sharedService.currentSubpage === 'stream-windows-apps' )">
    <sh-tab-item label="Overview" (click)="handleTabActive('')" [active]="!sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="KubeVirt Architecture" (click)="handleTabActive('kubevirt')" [active]="sharedService.currentTab == 'kubevirt'"></sh-tab-item>
    <sh-tab-item label="Prototype" (click)="handleTabActive('prototype')" [active]="sharedService.currentTab == 'prototype'"></sh-tab-item>
</sh-tabs>

<sh-tabs class="page-nav" *ngIf="( sharedService.currentChapter === 'api-management' && 
                                   sharedService.currentPage === 'technology-fields' && 
                                   sharedService.currentSubpage === 'graphql' )">
    <sh-tab-item label="Overview" (click)="handleTabActive('')" [active]="!sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="Architecture" (click)="handleTabActive('architecture')" [active]="sharedService.currentTab"></sh-tab-item>
    <sh-tab-item label="Server" (click)="handleTabActive('server')" [active]="sharedService.currentTab == 'server'"></sh-tab-item>
    <sh-tab-item label="Client" (click)="handleTabActive('client')" [active]="sharedService.currentTab == 'client'"></sh-tab-item>
    <sh-tab-item label="Tooling" (click)="handleTabActive('tooling')" [active]="sharedService.currentTab == 'tooling'"></sh-tab-item>
    <sh-tab-item label="File Upload" (click)="handleTabActive('file-upload')" [active]="sharedService.currentTab == 'file-upload'"></sh-tab-item>
    <sh-tab-item label="Type Safety" (click)="handleTabActive('type-safety')" [active]="sharedService.currentTab == 'type-safety'"></sh-tab-item>
    <sh-tab-item label="Authorization" (click)="handleTabActive('auth')" [active]="sharedService.currentTab == 'auth'"></sh-tab-item>
    <sh-tab-item label="Performance" (click)="handleTabActive('performance')" [active]="sharedService.currentTab == 'performance'"></sh-tab-item>
</sh-tabs>

<div class="md-wrapper" id="md-wrapper">
  <app-sandbox *ngIf="findComponent() &&
                      !sharedService.currentTab &&
                      (!sharedService.currentSubpage || sharedService.currentSubpage == 'overview')"
                [component]="findComponent()">
  </app-sandbox>
  <markdown ngPreserveWhitespaces [src]="getMarkdownSrc()"></markdown>
</div>