<sh-page [theme]="sharedService.appTheme">
  <sh-pane slot="right">
    <sh-text size="header-2" slot="header">Properties</sh-text>
    <!-- repeat for each prop -->
    <ng-container *ngFor="let prop of component.properties; index as i">
      <!-- string props -->
      <sh-input-text
        *ngIf="prop.type == 'string'"
        [label]="prop.name"
        [value]="prop.value"
        (value-changed)="
          initialized ? (prop.value = $event.target.value) : ''; setDemo()
        "
      >
      </sh-input-text>
      <!-- number props -->
      <sh-input-number
        *ngIf="prop.type == 'number'"
        [label]="prop.name"
        [value]="prop.value"
        (value-changed)="
          initialized ? (prop.value = $event.target.value) : ''; setDemo()
        "
      >
      </sh-input-number>
      <!-- boolean props -->
      <sh-checkbox
        *ngIf="prop.type == 'boolean'"
        style="padding-top: 8px;"
        [label]="prop.name"
        [active]="prop.value"
        (active-changed)="
          initialized ? (prop.value = $event.target.active) : ''; setDemo()
        "
      >
      </sh-checkbox>
      <!-- select props -->
      <sh-dropdown *ngIf="prop.type == 'select'"
        [label]="prop.name" 
        [value]="prop.value"
        >
        <sh-menu-item
          *ngFor="let option of prop.options"
          [label]="option ? option : 'undefined'"
          [active]="prop.value == option"
          (active-changed)="
            initialized && $event.target.active ? (prop.value = option) : '';
            setDemo()
          "
        >
        </sh-menu-item>
      </sh-dropdown>
    </ng-container>
    <!-- copy code -->
    <sh-button
      slot="footer"
      color="secondary"
      label="Copy Code"
      size="auto"
      (click)="copyCodeSnippet()"
    ></sh-button>
  </sh-pane>
  <div class="demo-wrapper"></div>
</sh-page>
