<sh-access-bar label="DevX Knowledge Share Portal">
    <sh-user-identifier slot="user" id="target-1a" [name]="user.displayName">      
    </sh-user-identifier>    
</sh-access-bar>

<sh-popover *ngIf="isAuthenticated"
            target="target-1a" 
            position="bottom-left" 
            id="log-info-popover">
        <sh-menu-item label="Logout" icon="logoff" (click)="logOut()"></sh-menu-item>
</sh-popover>

