import { Injectable } from '@angular/core';
import { MsalService, } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { User } from './User';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
        
    constructor(private msalService: MsalService) { 

        this.msalService.instance.handleRedirectPromise().then( response => { 
            if ( response != null && response.account != null) {
                localStorage.setItem("account", response.account.name);
                this.msalService.instance.setActiveAccount(response.account);
            }
        });
    }

    login() {        
        this.msalService.instance.loginRedirect();
    }

    logout() {
        localStorage.clear();
        this.msalService.instance.setActiveAccount(null);
        this.msalService.logout();
    }
    
    isLoggedIn() : boolean {
        return this.msalService.instance.getActiveAccount() != null;
    }

    getUser(): any {
        const user = new User();
        user.displayName = localStorage.getItem("account");;
        return user;
    }
}