import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class sharedService {
  public appTheme: string = 'light';

  // Array of Pages on which the Tab Navigation should not be displayed
  // Path example: 'access-bar/overview'
  public noTabNavPathList: any = [
    'overview',
    'image-viewing/overview'
  ];  
  public currentChapter: string = 'welcome';
  public currentPage: string = 'introduction';
  public currentSubpage: string;
  public currentTab: string = '';
  public currentPath: string = '';

  constructor() {}

  public getFullRoute(): any {
    const tabPath = !this.currentTab ? '' : `_${this.currentTab}`;
    const pagePath = this.currentSubpage
      ? `${this.currentPage}/${this.currentSubpage}`
      : `${this.currentPage}`;
    this.currentPath = pagePath;
    return `${this.currentChapter}/${pagePath}${tabPath}`;
  }
}
