import { Component, OnInit } from '@angular/core';
import { sharedService } from './services/shared.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor( public sharedService: sharedService,
               public authService: AuthService ) { }

  ngOnInit() {}
  
  get isAuthenticated() : boolean {
    return this.authService.isLoggedIn();
  }
}
