import { Component, OnInit, HostBinding } from '@angular/core';
import { sharedService } from '../../services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as sandbox from 'src/assets/sandbox.json';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit  {
  public mdPath: string;
  public sandbox: any[] = (<any>sandbox).default;

  @HostBinding('style.padding-top') 
  hostPaddingTop: string;

  constructor( public sharedService: sharedService,
               private router: Router,
               private acivatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {    
    this.setPropsFromParams();
  }

  public setPropsFromParams(): void {
    const pageSplit = this.acivatedRoute.snapshot.paramMap.get('page').split(/[\/ _]/);    
    this.sharedService.currentPage = pageSplit[0];
    this.sharedService.currentSubpage = this.acivatedRoute.snapshot.paramMap.get('subpage');
    this.sharedService.currentChapter = this.acivatedRoute.snapshot.paramMap.get('chapter');
    this.sharedService.currentTab = pageSplit[1];
  }

  public getMarkdownSrc(): string {

    if( this.isChapterContainsTabPage() ) {
        this.hostPaddingTop = "120px"; 
      }
      else {
        this.hostPaddingTop = "60px";
      }

    return `./assets/markdown/${this.sharedService.getFullRoute()}.md`;
  }

  public handleTabActive( tabName ): void {
    this.sharedService.currentTab = tabName;
    this.router.navigateByUrl(this.sharedService.getFullRoute());
    document.getElementById("md-wrapper").scrollTop = 0;
  }

  public findComponent(): boolean {
    return this.sandbox.find(({ name }) => name == this.sharedService.currentPage);
  }

  private isChapterContainsTabPage() : boolean {

    let hasTabs: boolean = false;
    let ChaptersWithTabs: any = [
      {
        chapter: 'interoperability',
        page: 'technology-fields',
        subPage: 'cloud-setup'
      },
      {
        chapter: 'interoperability',
        page: 'technology-fields',
        subPage: 'on-premise-setup'
      },
      {
        chapter: 'interoperability',
        page: 'technology-fields',
        subPage: 'hybrid-setup'
      },
      {
        chapter: 'applicationaccess',
        page: 'technology-fields',
        subPage: 'stream-windows-apps'
      },
      {
        chapter: 'graphql',
        page: 'technology-fields',
        subPage: 'graphql'
      }
    ];

    for (let element of ChaptersWithTabs) {
      if (element.chapter === this.sharedService.currentChapter &&
        element.page === this.sharedService.currentPage &&
        element.subPage === this.sharedService.currentSubpage
      ) {
        hasTabs = true;
        break;
      }
    }

    return hasTabs;
  }
}
