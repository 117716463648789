import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { sharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent implements OnInit {
  @Input() component: any;
  public codeSnippet: any;
  public initialized: boolean = false;

  constructor(public sharedService: sharedService, public router: Router) {}

  ngOnInit(): void {
    const self = this;
    function initializeSandbox() {
      self.initialized = false;
      setTimeout(() => {
        self.initialized = true;
        //self.setDemo();
      }, 0);
    }
    this.router.events.subscribe(() => initializeSandbox());
    initializeSandbox();
  }

  public setDemo(): void {
    let wrapper = document.querySelector('.demo-wrapper');
    let innerEl = document.createElement(
      `sh-${this.component.tag ? this.component.tag : this.component.name}`
    );
    if(this.component.tag) {
      var outerEl = document.createElement(
        `sh-${this.component.name}`
      );
    }
    this.component.properties.forEach((prop) => {
      if (!prop.value) {
        innerEl.removeAttribute(prop.name);
      } else {
        innerEl.setAttribute(prop.name, prop.value);
      }
    });
    if (wrapper) {
      wrapper.innerHTML = '';
      if(outerEl) {
        outerEl.appendChild(innerEl)
        wrapper.appendChild(outerEl);
      } else {
        wrapper.appendChild(innerEl);
      }
    }
    this.codeSnippet = innerEl.outerHTML;
  }

  public copyCodeSnippet(): void {
    const el = document.createElement('textarea');
    el.value = this.codeSnippet;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
