import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './components/content/content.component';

// declare routes and add fallback for home
const routes: Routes = [
  {
    path: ':chapter',
    children: [
      {
        path: ':page',
        children: [
          {
            path: '',
            component: ContentComponent,
          },
          {
            path: ':subpage',
            component: ContentComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    redirectTo: '/welcome/devx-introduction',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
